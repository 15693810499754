@media (max-width: 900px) {
    .tldr-content {
        padding-top: 10px;
        padding-left: 10px;
        width: 95%; /* Override the fixed width for smaller screens */
        margin: 0 auto; /* Center the content horizontally */
        text-align: justify; /* Justify the paragraph text */
        hyphens: auto; /* Enable hyphenation */
    }
}

@media (min-width: 901px) {
    .tldr-content {
        padding-top: 10px;
        padding-left: 10px;
        width: 95%; /* Ensure the content takes full width of its grid cell */
        margin: 0 auto; /* Center the content horizontally */
        text-align: justify; /* Justify the paragraph text */
        hyphens: auto; /* Enable hyphenation */
    }
}