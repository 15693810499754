/* src/Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
}
  
.login-button {
  margin-top: 20px;
  width: 100%;
  max-width: 300px; /* Ensure button is not too wide */
  min-height: 50px; /* Ensure button has some height */
  background-color: #f0f0f0; /* Temporary background color to ensure visibility */
}

.loading {
  font-size: 18px;
  color: #555;
}

.error-message {
color: red;
margin-top: 20px;
}