
.app-header {
    background-color: #ff6600;
    color: white;
    padding:10px 40px;
    text-align: left;
    align-items: center;  /* Center items vertically */
    justify-content: space-between;
    display: flex;  
}

.home-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
}

.icon-large {
    font-size: 1.5em; /* Adjust the size as needed */
}

.dropdown {
    position: relative;
    float: right;
}
  
.dropdown-button {
    background-color: #ff6600;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.dropdown-menu {
    display: block;
    position: absolute;
    right: 0;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
}

.dropdown-menu li {
    list-style: none;
    z-index: 1000;
}

.dropdown-menu li a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-menu li a:hover {
    background-color: #ddd;
}