/* src/Pagination.css */
.pagination {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.page-item {
  margin: 0 5px;
  padding: 8px 14px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.page-item:hover {
  background-color: #f0f0f0;
  color: #000;
}

.page-item.active {
  background-color: #ff6600;
  color: #fff;
  border-color: #ff6600;
}

.ellipsis {
  margin: 0 5px;
  padding: 8px 14px;
  color: #888;
}

.page-item:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.page-item:disabled:hover {
  background-color: #fff;
  color: #333;
}

.pagination .page-item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination .page-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
