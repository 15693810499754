/* Acknowledgement.css */

.acknowledgement-content {
    max-width: auto;
    margin: 0 auto;
    padding: 40px;
    text-align: left;
    background-color: #f9f9f9;
  }
  
  .acknowledgement-content h2 {
    font-size: 2.0em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .acknowledgement-content p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .acknowledgement-content p a {
    color: #007bff;
    text-decoration: none;
  }
  
  .acknowledgement-content p a:hover {
    text-decoration: underline;
  }
  
  .back-link {
    display: inline-block;
    margin-top: 20px;
    font-size: 1.1em;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .back-link:hover {
    color: #0056b3;
  }