.story-highlights {
  text-align: left;
  margin-top: 20px;
}
  
.loading-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
  position: relative;
}
  
.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}


/* Styles for larger screens (min-width: 901px) */
@media (min-width: 901px) {
  
  .highlight-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    margin-bottom: 5px;
    position: relative;
    flex-direction: row; /* Default to row */
  }
  

  .highlight-theme {
    flex: 0 0 25%;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
  }
  
  .highlight-theme::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 80%;
    background-color: #ddd;
    z-index: 1;
  }
  
  .highlight-details {
    flex: 1;
  }
  
  .highlight-details ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: justify; /* Justify the paragraph text */
    hyphens: auto; /* Enable hyphenation */
    line-height: 1.6; /* Improve readability */
  }
  
}

@media (max-width: 900px) {
  .highlight-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    position: relative;
    flex-direction: column; /* Default to row */
  }

  .highlight-theme {
    font-weight: bold;
    display: flex;
    text-align: center;
    position: relative;
  }

  .highlight-details {
    width: 100%;
    text-align: justify;
    hyphens: auto;
    line-height: 1.6;
    /* Add overflow protection */
    overflow-wrap: break-word;
  }

  /* Fix bullet point overflow */
  .highlight-details ul {
    padding-left: 4px; /* Reduced from default 2rem */
    list-style-position: inside; /* Keep bullets within container */
  }

  .highlight-details li {
    word-break: break-word; /* Prevent long words from overflowing */
    margin-left: 0;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}