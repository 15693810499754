.comment-themes {
    text-align: left;
    margin-top: 20px;
}

.themes-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
  
.theme-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
}
  
@media (max-width: 900px) {
    .theme-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .theme-image {
        width: 100%;
        height: auto;
    }

    .theme-content {
        width: 100%;
        text-align: justify;
        hyphens: auto;
        /* Add these list adjustments */
        overflow-wrap: break-word;
    }
    
    .theme-content ul {
        padding-left: 1.5rem; /* Reduced from default 2rem */
        list-style-position: inside; /* Keep bullets within content box */
    }
    
    .theme-content li {
        margin-left: 0;
        word-break: break-word; /* Prevent long words from overflowing */
    }
}

@media (min-width: 901px) {
    .theme-item {
        display: grid;
        grid-template-columns: 3fr 7fr; /* Adjust the ratio here */
        align-items: center;
    }

    .theme-image {
        width: 100%; /* Ensure the image takes full width of its grid cell */
        height: auto;
    }

    .theme-content {
        width: 100%; /* Ensure the content takes full width of its grid cell */
        text-align: justify; /* Justify the paragraph text */
        hyphens: auto; /* Enable hyphenation */
    }
}

  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}