/* filepath: /Users/sitewang/hackercomments_frontend/src/components/Footer.css */
.app-footer {
    background-color: #f4f4f4;
    color: white;
    padding: 5px 0; /* Reduced padding */
    width: 100%;
    text-align: center;
    box-shadow: 0 -2px 5px #00000033;
    z-index: 1000; /* Ensure the footer is on top */
    flex-shrink: 0;
}

.app-footer nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 2px;
}

.app-footer nav ul li {
    display: inline;
}

.app-footer nav ul li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 5px 10px; /* Adjusted padding */
    transition: background-color 0.3s;
}

.app-footer nav ul li a:hover {
    background-color: #ff4d4d;
}

.app-footer nav ul li:not(:last-child)::after {
    content: "|";
    color: #a2a2a2; /* Grey color for the pipe */
    margin-left: 10px; /* Space after the link */
    padding-right: 10px; /* Space before next link */
  }