/* Reset some basic elements to provide a consistent baseline */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  body {
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
  }
  
  /* General styles for the application */
  .App {
    text-align: center;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  
  /* Styling for individual story details */
  .story-detail {
    background: white;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }
  
  /* Styling for news topics and user perspectives sections */
  .news-topics, .user-perspectives {
    margin-top: 10px;
    text-align: left;
  }
  
  /* Styling for the headings within sections */
  .news-topics h3, .user-perspectives h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  /* Styling for lists within sections */
  .news-topics ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .user-perspectives div {
    margin-bottom: 10px;
  }
  