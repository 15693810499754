.contact-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-grow: 1; /* Add this line */
    min-height: calc(100vh - 200px); /* Adjust 200px based on header/footer height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(145deg, #ffffff, #f8f9fa); 
  }

.contact-container h2 {
    font-size: 2.2rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 3px solid #ff6600;
    display: inline-block;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: auto; /* Prevent form from growing */
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-group label {
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    gap: 8px;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    transition: all 0.3s ease;
    padding-left: 35px; /* Space for icons */
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #ff6600;
    box-shadow: 0 0 8px rgba(0,123,255,0.25);
    outline: none;
}

.form-group label svg {
    width: 20px;
    height: 20px;
    fill: #ff6600;
}

.form-group {
    position: relative;
}

.form-group svg {
    position: absolute;
    left: 10px;
    top: 44px;
    width: 18px;
    height: 18px;
    fill: #666;
}

.submit-button {
    background-color: #ff6600;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
    transform: translateY(0);
    transition: all 0.3s ease;
}

.submit-button:hover {
    background-color: #ff6600;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,86,179,0.3);
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.success-message {
    background: #d4edda;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #c3e6cb;
}

.return-home {
    margin-top: 20px;
    text-align: center;
}

.return-home a {
    color: #ff6600;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.return-home a:hover {
    color: #ff6600;
    text-decoration: underline;
}