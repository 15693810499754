/* About.css */

.about-content {
    max-width: auto;
    margin: 0 auto;
    padding: 40px;
    text-align: left;
    background-color: #f9f9f9;
  }
  
  .about-content h2 {
    font-size: 2.0em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about-content p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .about-content p:last-child {
    margin-bottom: 0;
  }