.mind-map {
  text-align: center;
  margin-top: 20px;
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent overflow issues */
}

.graph-container {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loading-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px; /* Set a fixed width for better alignment */
  margin: 0 auto; /* Center the button horizontally */
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
  position: absolute; /* Position it absolutely within the container */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for absolute centering */
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}