/* frontend/src/components/StoryDetail.css */
.story-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.story-list li {
  padding: 10px;
  cursor: pointer;
  word-break: break-word; /* Ensure long words break */
}

.story-list li:hover {
  background-color: #f0f0f0;
}

.news-topics {
  margin-bottom: 20px;
}

.news-topics h3 {
  margin-bottom: 10px;
}

.news-topics ul {
  list-style-type: disc;
  padding-left: 20px;
}

.user-perspectives {
  margin-top: 20px;
}

.perspective-item {
  margin-top: -1px;
  padding: 12px 16px;
  margin-bottom: 4px;
  border-radius: 15px;
  max-width: 85%;
  position: relative;
  word-wrap: break-word;
  font-size: 0.95rem;
  line-height: 1.4;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.perspective-item.left {
  background-color: #dedede;
  color: #000;
  border-radius: 15px 15px 15px 4px;
  align-self: flex-start;
  text-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.perspective-item.right {
  background-color: hsla(210, 85%, 55%, 0.95); /* More readable blue */
  background-image: linear-gradient(
    to bottom,
    hsla(210, 85%, 60%, 0.95),
    hsla(210, 85%, 50%, 0.95)
  );
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,0.1);
  border-radius: 15px 15px 4px 15px;
  margin-left: auto;
  margin-right: 8px;
  max-width: 78%;  /* Reduced from 85% */
  min-width: 40%;   /* Prevent too narrow bubbles */
  width: fit-content; /* Better text wrapping */
  word-break: break-word;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}



.perspective-item.left::after {
  content: '';
  position: absolute;
  left: -5px;  /* Adjusted from -6px */
  bottom: 4px;  /* Matches border-radius size */
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-right-color: #F1F1F1;
  border-left: 0;
  border-bottom: 0;
}

.perspective-item.right::after {
  content: '';
  position: absolute;
  right: -5px;  /* Adjusted from -6px */
  bottom: 4px;  /* Matches border-radius size */
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-left-color: #007AFF;
  border-right: 0;
  border-bottom: 0;
}

.perspective-container {
  display: flex;
  flex-direction: column;
  margin: 4px 0; /* Reduced container margin */
}

/* Username styling */
.perspective-username {
  font-size: 0.8rem; /* Slightly larger */
  color: #666;
  margin: 0 8px 1px; /* Reduced bottom margin */
  display: block;
  max-width: 78%;
  position: relative;
  top: -1px; /* Nudge closer to bubble */
}

.perspective-container.left .perspective-username {
  align-self: flex-start;
  text-align: left;
}

.perspective-container.right .perspective-username {
  align-self: flex-end;
  text-align: right;
}

.segment-line {
  border: none;
  height: 2px;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  margin: 20px 0;
}

.story-detail-header {
  display: flex;
  align-items: baseline;
}

.story-detail-header-title {
  font-size: 24px; /* Responsive font size based on viewport width */
  font-weight: bold;
  margin-right: 0.5em;
}

.story-detail-header-url {
  font-size: 16px; /* Responsive font size based on viewport width */
  color: gray;
  margin-left: 0.5em;
}


@media (max-width: 900px) {
  .story-detail-header-title {
    font-size: 1.4rem; /* Slightly larger for readability on small screens */
  }

  .story-detail-header-url {
    font-size: 0.8rem; /* Slightly larger for readability on small screens */
  }
}