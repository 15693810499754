/* frontend/src/components/StoryList.css */
.story-list {
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  background-color: #f6f6ef; /* Background color for the story list */
}

.story-list ol {
  padding-left: 20px;
  cursor: pointer;
  list-style-type: decimal;
  margin-bottom: 10px; /* Add space between list items */
  font-size: 18px;
}

.story-list ul {
  list-style-type: none;
  padding: 0;
}


.story-list li:hover {
  background-color: #f0f0f0;
}

.story-list li.selected {
  background-color: #007bff;
  color: white;
}

.story-title {
  font-size: 18px; /* Bigger font for the title */
  font-weight: bold;
}

.story-meta {
  font-size: 14px; /* Smaller font for the second row */
  line-height: 1.1; /* Adjust the line height to control space between lines */
  color: #858585; /* Color for the hyperlink */
}

.story-meta a {
  color: #858585; /* Color for the hyperlink */
  text-decoration: none;
}

.story-meta a:hover {
  text-decoration: underline;
}